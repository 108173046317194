import React from "react";

import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";

import Assignment from "@material-ui/icons/Assignment";
import withStyles from "@material-ui/core/styles/withStyles";

import moment from 'moment'
import GridContainer from "components/Grid/GridContainer.jsx"
import GridItem from "components/Grid/GridItem.jsx"
import Card from "components/Card/Card.jsx"
import CardBody from "components/Card/CardBody.jsx"
import CardIcon from "components/Card/CardIcon.jsx"
import PropTypes from 'prop-types';
import Close from "@material-ui/icons/Close"

import IconButton from "@material-ui/core/IconButton";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLess from "@material-ui/icons/ExpandLess";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import Button from "@material-ui/core/Button";
import ButtonCustom from "@material-ui/core/Button";

import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';

//import Height from '@material-ui/icons/Height'
//import FormatLineSpacingSharpIcon from '@material-ui/icons/FormatLineSpacingSharp'

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";

import CustomButton from "components/CustomButtons/Button.jsx";

import "video-react/dist/video-react.css";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CircularProgress from "@material-ui/core/CircularProgress";

import BivExportCsvButton from "components/BivExportCsvButton/BivExportCsvButton.jsx";
import "assets/jss/material-dashboard-pro-react.jsx";

import columnTable from "utilities/columnTable.jsx";

//import * as FileSaver from 'file-saver';

import BivProctoringFilter from "components/BivProctoringFilter/BivProctoringFilter.jsx";
import BivDivider from "components/BivDivider/BivDivider.jsx";
import BivResult from "components/BivResult/BivResult.jsx";
import BivProctoringTable from "components/BivProctoringTable/BivProctoringTable.jsx";

import KWValidationFormProfile from "components/KWValidationFormProfile/KWValidationFormProfile.jsx";
import KWValidationFormVideo from "components/KWValidationFormVideo/KWValidationFormVideo.jsx";
import KWValidationFormAuditResult from "components/KWValidationFormAuditResult/KWValidationFormAuditResult.jsx";

import KWZoomButton from "components/KWZoomButton/KWZoomButton.jsx";
import KWAnomaliesSummary from "components/KWAnomaliesSummary/KWAnomaliesSummary.jsx";

import ValidationLabel from "components/ValidationLabel/ValidationLabel.jsx";

//import { PDFDownloadLink, Page, Text, View, Document } from '@react-pdf/renderer';

import utilData from "utilities/utilData.jsx";
import utilities from "utilities/utilities.jsx";

import db from 'db/db.jsx'
import { AppBar, Box, Collapse, Divider, Paper, Tab, Tabs, Tooltip } from "@material-ui/core"
import Modal from '@material-ui/core/Modal';
import { makeStyles } from "@material-ui/styles"
import { borderRadius } from "@mui/system"

import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Problem from "@material-ui/icons/ReportProblem";
import Image from "react-bootstrap/Image";
import * as firebase from "firebase";
import Typography from '@material-ui/core/Typography';
import { Grid } from "@mui/material";
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';

const ColorButton = styled(Button)(({ theme }) => ({
  color: "#fff",
  backgroundColor: "#009EFD",
  '&:hover': {
    backgroundColor: "#009EFD",
  },
}));
const ColorButtonOutlined = styled(Button)(({ theme }) => ({
  color: "#009EFD",
  backgroundColor: "#fff",
  borderColor:"#009EFD",
  '&:hover': {
    backgroundColor: "#fff",
  },
}));
moment.locale("es");

const languages = require("../../assets/translate/translate_examnsNewAudit.json");
let lang = localStorage.lang ? localStorage.lang : "es";
const languages1 = require('../../assets/translate/translate_button.json')
const StyledTabs = withStyles({
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    '& > span': {
      width: '70%',
      minWidth: 40,
      backgroundColor: 'lightblue',
    },
  },
  borderRadius: 15,

})((props) => <Tabs {...props} scrollButtons="auto"
  aria-label="Intentos" variant="scrollable" TabIndicatorProps={{ children: <span /> }} />);

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles({
  root: {
    background: (props) =>
      props.active === 'selected'
        ? '#FFF'
        : '#eaeaea',
    textTransform: 'none',
    color: '#222',
    outline: "none",
    // borderTopLeftRadius: 7,
    // borderTopRightRadius: 7,
    // borderLeft: "solid 1px #929292",
    // borderRight: "solid 1px #929292",
    // borderTop: "solid 1px #929292",
    fontWeight: '600',
    fontSize: '16',
  },
});

function StyledTab(props) {
  const { active, n, ...other } = props;
  const classes = useStyles(props);
  return <Tab
    {...a11yProps(n)}
    style={{ outline: "none" }} className={classes.root} disableRipple {...other} />;
}


StyledTab.propTypes = {
  active: PropTypes.oneOf(['selected', 'not']).isRequired,
};

//{languages[lang].dash_criticalValidationExams}

class ExamnsNewAudit extends React.Component {
  constructor(props) {
    super(props);
    //this.myRef = React.createRef();
    this.myRef = React.createRef();
    let rol = JSON.parse(localStorage.rol).admin
    let isAdmin = true
    if (!rol) {
      isAdmin = false
    }

    this.state = {
      //Show loading icon
      showAlreadyDone: {
        show: false,
        from: 0,
        alertData:{}
      },
      loading: true,
      //Store data
      resultToTable: [],
      //Store data to Examine
      resultToExamine: [],
      _resultToExamine: [],
      _2resultToExamine: [],
      //Store incidences data to Examine
      //incidencesToExamine: [],
      //Store page number
      pageNumber: 1,
      totalProctoring: 0,
      totalRegistry: 0,
      //Store pagination order value
      orderChange: false,
      order: "desc",
      column: "createdAt",

      //Store variable to load data to export
      toExport: false,

      //Store variable to force load data to table
      forceLoadData: false,

      //Show validate modal
      showValidateForm: false,

      //Show section info profile in modal
      showProfileInfo: false,

      //Show section info pc in modal
      showInfoPc: false,
      //Show video section
      showVideoAudit: true,
      //incidencesTableScroll: false,
      //showVideoHorizontal: false,
      //Store variable to audit
      startOfAudit: 0,
      auditResult: 1,
      auditText: "",
      //Incidence filter
      incidenceFilter: "",
      //Table value zoom
      tableZoom: 1,
      filteredAnomaly: null,
      //scrollTop:null
      //myRef : React.createRef()
      goToViolation: null,
      //Date to query
      searchStartDate: moment()
        .subtract(15, "days")
        .format("YYYY-MM-DD"),
      searchEndDate: moment().format("YYYY-MM-DD"),
      //Store electronic validation selected
      searchElectronicValidation: "Todas",
      //Store exam type selected
      searchExamType: [],
      //Store user name
      searchUserName: "",
      //Store file
      searchfile: "",
      //Store subject
      searchSubject: [],
      searchCanvasSubject: [],
      //Store page
      //Store dni
      searchdni: "",
      //Store nickName
      searchNickName: "",
      searchAudit: "all",
      showModalMassiveNotif: false,

      tabselected: 0,
      videoSelected: false,
      _fechaHora: "",

      showVe: false,
      _completeElCampo: {},
      textDni: '',
      textName: '',
      textBirDate: '',
      textSex: true,
      textLastName: '',
      textDescription: '',
      isAdmin: isAdmin,
    };
  }

  //estados y funciones traidas de BivEnroll para mostras informacion de empadronamientos en el modal de auditoria de examen

  setStatus(a, b) {
    // let myHeaders = new Headers();
    // myHeaders.append("Content-Type", "application/json");
    // let _data_ = JSON.stringify({ "user": a, "st": b })
    // let requestOptions = {
    //   method: 'POST',
    //   headers: myHeaders,
    //   body: _data_,
    //   redirect: 'follow'
    // };

    // fetch(Direction_Back_panel + "/saveStatus", requestOptions)
    //   .then(response => response.text())
    //   .then(result => { })
    //   .catch(error => console.log('error', error))
  }

  nameCleaner(name) {
    if (name.length > 2) {
      if (name.substring(0, 2).toUpperCase() === "ID") {
        let _name = name.substring(2, name.length)
        return _name.toString().toUpperCase()
      }
    } else {
      return name.toString().toUpperCase()
    }
  }

  checkData() {
    if (!this.state.enrollFinalized) {
      let _resp = false
      let _checkDNI = true
      let _checkBirDate = false
      let _checkName = false
      let _checkLastName = false
      let _userVerify = false
      let _descriptionDenied = false

      let _completeElCampo = {
        dni: false,
        birDate: false,
        name: false,
        lastname: false
      }

      //Dni check

      // if (this.state.textDni.length > 2) { _checkDNI = true }

      // if (!this.state.textDni || this.state.textDni.length === 0 || this.state.textDni < 800000) { _completeElCampo.dni = true }

      //Bir. check
      if (this.state.textBirDate) {

        if (moment(this.state.textBirDate).valueOf() < moment(Date.now()).valueOf()) {
          _checkBirDate = true
        }
      }

      if (!this.state.textBirDate || this.state.textBirDate.length === 0) { _completeElCampo.birDate = true }


      //Name check
      if (this.state.textName && this.state.textName.length > 2) { _checkName = true }

      if (!this.state.textName || this.state.textName.length < 3) { _completeElCampo.name = true }


      //Last name check
      if (this.state.textLastName && this.state.textLastName.length > 2) { _checkLastName = true }

      if (!this.state.textLastName || this.state.textLastName.length < 3) { _completeElCampo.lastname = true }


      //Detalle check
      if (+this.state._userVerify === 1) { _userVerify = true; _descriptionDenied = true }

      if (+this.state._userVerify === 2 && this.textDescription && this.textDescription.length > 5) { _userVerify = true; _descriptionDenied = true }

      //General check
      if ((_checkDNI && _checkBirDate && _checkName && _checkLastName && _userVerify && _descriptionDenied) || (+this.state._userVerify === 2 && _userVerify && _descriptionDenied)) {
        _resp = true
      }

      this.setState({ buttonAccept: _resp, _buttonAccept: _resp, "_completeElCampo": _completeElCampo }, () => {
        return _resp
      })

    }
    return false
  }

  checkDesciptionData() {
    let _res = false
    if (this.textDescription.length > 5) {
      _res = true
    }
    this.setState({ dismissAcceptDialog: _res, _dismissAcceptDialog: _res }, this.checkData)
  }

  verUs(x) {
    this.setState({ _userVerify: x }, this.checkData)

    return true
  }

  handleKeyPress(e) {
    if (e.key === "Enter") {
      this.acceptDismissEnroll()
    }
  }

  handleKeyDown(e) {
    if (e.keyCode === 27) {
      this.cancelDismissEnroll()
    }
    if (e.key === "Enter") {
      this.setState({ loading: true }, this.acceptEnroll)
      // this.acceptEnroll()
    }
  }

  handleOnChange(e) {
    this.textDescription = e.target.value
    this.checkDesciptionData()
    // this.setState({ textDescription: e.target.value }, this.checkDesciptionData)
  }

  acceptEnroll() {
    let _enroll = {};
    let time = Date.now();
    let actualUser = this.state.resultToExamine.enroll;

    //Cuando acepto un empadronamiento manual cambio mr:true, r:1 y sr:1
    let _userDataGenerated = {
      dni: this.state.textDni,
      n: this.state.textName,
      s: this.state.textLastName,
      t: time,
    };
    let _userData = actualUser.d;
    let changes = false;
    if (!_userData) {
      changes = true;
    } else {
      if (_userData.n !== _userDataGenerated.n || _userData.s !== _userDataGenerated.s || _userData.dni !== _userDataGenerated.dni) {
        changes = true;
      }
    }

    //Cuando acepto un empadronamiento manual cambio mr:true, r:1 y sr:1
    if (changes) {
      _enroll["d"] = _userDataGenerated;
    }
    let status;
    _enroll["ve"] = this.state._userVerify;
    if (+this.state._userVerify === 1) {
      _enroll["det"] = null;
      _enroll["me"] = "success";
      status = "1";
    } else if (+this.state._userVerify === 2) {
      _enroll["det"] = this.textDescription;
      _enroll["mr"] = true;
      status = "2";
    }
    if ((actualUser.ty + "" === "2" || actualUser.ty + "" === "3") && actualUser.r + "" === "0" && status + "" === "1") {
      _enroll["i0"] = {
        i: actualUser.userimg,
        t: Number(time),
      };

      _enroll["i1"] = {
        i: actualUser.dniimg1,
        t: Number(time),
      };
    }
    _enroll["r"] = status;
    _enroll["t"] = Number(time);
    //fecha de actualizacion
    _enroll["ut"] = Number(time);
    //fecha de auditoria
    _enroll["la"] = Number(time);
    if (status == 2) {
      _enroll['pb'] = false
    }
    const sendPadronInfo = () => {
      let formdata = new FormData();
      let myHeaders = new Headers();
      let _token = localStorage.getItem("fb_jwt");
      myHeaders.append("Authorization", "Bearer " + _token);
      formdata.append("id", actualUser.userid);

      let _enroll2 = {
        ve: _enroll.ve,
        det: _enroll.det || null,
        r: _enroll.r,
        me: _enroll.me || null,
        mr: _enroll.mr || null
      }
      formdata.append("data", JSON.stringify((!global.rol.authorities.permission.editEnrolls ? _enroll2 : _enroll)));
      let requestOptions = {
        headers: myHeaders,
        method: "POST",
        body: formdata,
        redirect: "follow",
      };
      fetch(global.Direction_Back_panel + "/panel/setPadronInfo", requestOptions)
        .then((response) => response.text())
        .then((result) => {
          firebase
            .database()
            .ref("padronInformation/" + actualUser.userid)
            .once("value")
            .then((_user_) => {
              _user_ = _user_.val();
              let notif;
              if ((_user_.ty + "" === "2" || _user_.ty + "" === "3") && status + "" === "1") {
                notif = 1;
              }
              if ((_user_.ty + "" === "2" || _user_.ty + "" === "3") && status + "" === "2") {
                notif = 2;
              }
              if (_user_.ty + "" === "1" && status + "" === "2") {
                notif = 3;
              }
              if (_user_.ty + "" === "1" && status + "" === "1") {
                notif = 1;
              }
              this.setStatus(_user_, notif);
            });
          this.validateActionButton(this.state.resultToExamine.oldDataToValidate)
          this.setState(
            {
              showEnrollDetail: false,
              dismissDialog: false,
              photoDialog: false,
              loading: false,
              showVe: !this.state.showVe,
            }
          );
        })
        .catch((error) => console.log("error", error));
    }
    const sendRequest = () => {
      let myHeaders = new Headers();
      let _token = localStorage.getItem("fb_jwt");
      myHeaders.append("Authorization", "Bearer " + _token);

      let formdata = new FormData();
      formdata.append("id", actualUser.userid);
      formdata.append(
        "data",
        JSON.stringify({
          r: 1,
          a: this.state.textLastName,
          n: this.state.textName,
          s: this.state.textSex === true ? true : false,
          f: Number(moment(this.state.textBirDate).format("x")),
          dn: this.state.textDni,
        })
      );
      let requestOptions = {
        headers: myHeaders,
        method: "POST",
        body: formdata,
        redirect: "follow",
      };
      fetch(global.Direction_Back_panel + "/panel/setRequest", requestOptions)
        .then((response) => response.text())
        .then((result) => {

          sendPadronInfo()
        })
        .catch((error) => console.log("error", error));
    }
    if (global.rol.authorities.permission.editEnrolls == 1) {
      sendRequest()
    }
    else {
      sendPadronInfo()
    }

  }


  acceptDismissEnroll() {
    const _enrollSelected = this.state.resultToExamine.userid
    firebase.database().ref('requests/' + _enrollSelected.userid).update({
      d: this.textDescription ? this.textDescription : "",
      i: _enrollSelected.userimg,
      i0: _enrollSelected.dniimg1,
      i1: _enrollSelected.dniimg2,
      k: _enrollSelected.userid,
      r: 2,
      //t: Number(this.state.enrollSelected.datetimestamp),
      a: this.state.textLastName,
      n: this.state.textName,
      s: (this.state.textSex === true) ? true : false,
      f: Number(moment(this.state.textBirDate).format('x')),
      dn: this.state.textDni,
    }, () => {

      //Cuando rechazo un empadronamiento manual cambio mr:true, r:2 y sr:2

      let _enroll = { r: "2", sr: "2", mr: true, det: this.textDescription }
      firebase.database().ref('padronInformation/' + _enrollSelected.userid).update(_enroll).then(() => {
        this.setState({
          showEnrollDetail: false,
          dismissDialog: false,
          photoDialog: false,
        }, this.newLoadData)
      })
    });

  }

  cancelarVerificacion() {
    this.setState({
      cancelButton: false,
    })
  }


  cancelDismissEnroll() {
    this.setState({
      _userVerify: null,
      _dismissDialog: false,
      dismissDialog: false,
      buttonDismiss: true,
      _buttonDismiss: true,
      textDescription: '',
      showEnrollDetail: false,
      photoDialog: false,
    }, this.checkData)
  }

  //myRef = React.createRef();
  scrollToMyRef = () => window.scrollTo(0, this.myRef.current.offsetTop);

  sortFunction(_field, _order) {
    this.setState(
      {
        orderChange: true,
        column: _field,
        order: _order,
        loading: true,
      },
      this.getExamns
    );
  }

  focusMethod = function getFocus() {
    if (document.getElementById("goTo")) document.getElementById("goTo").focus();
  };

  estadoPdf = async () => {
    const response = await fetch(global.Direction_Back_panel + "/v2/checkWorldClass", { method: "Post" })
    let j
    if (response.ok) {
      let json = await response.json();
      j = json.success;
    } else {
      //alert("Error-HTTP: " + response.status);
    }

    this.uni = j;
  };
  componentDidMount() {
    this.uni = false;

    this.estadoPdf();

    this.getExamns();
  }

  async getExamns() {
    let rol = global.rol;
    if (rol && rol.authorities && rol.authorities.permission && rol.authorities.permission.seeExamns) {
      let _preResult = await db.getExamns(this.state.searchElectronicValidation, this.state.searchExamType, this.state.searchUserName, this.state.searchfile, this.state.searchSubject, this.state.searchStartDate, this.state.searchEndDate, 0, this.state.pageNumber, this.state.order, this.state.column, this.state.searchdni, this.state.searchNickName, this.state.searchAudit, this.state.searchCanvasSubject, this.state.searchExamCanvas);

      let _page = _preResult.page;
      let _totalRegistry = _preResult.total;
      let _totalProctoring = _preResult.totalProctoring;

      let _result = _preResult.data;
      let _materias = _preResult.subjects;
      let canvas_materias = _preResult.course;
      let _examenes = _preResult.examType;

      this.setState({
        canvasSubjects: canvas_materias,
        subjects: _materias,
        typeOfExams: _examenes,
      });

      _result = await utilData.prepareExamnsToTable(_result, "Examn");

      //this.props.dataResult(_result, _page, _totalRegistry, _materias)
      //this.props.showLoading(false)

      this.setState({
        resultToTable: _result,
        pageNumber: _page,
        totalRegistry: _totalRegistry,
        totalProctoring: _totalProctoring,
        orderChange: false,
        loading: false,
      });
    } else {
      global.showNoPermission();
      this.setState({
        loading: false,
      });
    }
  }

  goToViolation = (id) => {
    const violation = document.getElementById(id);
    window.scrollTo({
      top: violation.current.offsetTop,
      //top: 300,
      behavior: "smooth",
    });
  };
  scrollTable() {
    this.myRef.current.scrollIntoView({ top: 100 });
    //this.myRef.scrollIntoView(0, this.myRef.current.offsetTop);
    //this.myRef.current.window.scrollTo(0, this.myRef.current.offsetTop)
    //$("#modalcontent").animate({scrollTop: $('#placeholder').offset().top}, 200);
    //scrollTo: $("#grepperRocks").offset().top
    //this.myRef.current.scrollIntoView(document.getElementById("tableAnomalies"));
    //document.getElementById("tableAnomalies")
  }
  formatWithIconSee(cell, row) {
    const rol = global.rol.authorities.permission
    return (
      <CustomButton
        color={(row.finalResultAuditory > -1 || !rol.editExamns) ? "darkVioletBiv" : "violetBiv"}
        style={{ width: "90%", verticalAlign: "middle" }}
        onClick={() => {
          this.validateActionButton(row,(row.finalResultAuditory > -1 || !rol.editExamns)?2:1);
        }}
        round
      >
        {(row.finalResultAuditory > -1 || !rol.editExamns) ? languages1[lang].button_watch : languages1[lang].button_audit}
      </CustomButton>
    );
  }


  proccessExamnData(_dataToValidate, result, oldDataToValidate,from) {

    this.setState({

    });

    let alldata = JSON.parse(result)
    _dataToValidate=JSON.parse(JSON.stringify(_dataToValidate))
    // en res tenes que guardar el que coincide con keyproctoring de _dataToValidate
    let res
    //en old data guarda los elementos que no coinciden con keyproctoring
    let oldData = []

    let showalert=false
    let alertData={
      user:null,
      time:null
    }
    alldata.map((checkItem)=>{

      if(checkItem.finalResultAuditory){
        showalert=true
        alertData.user=checkItem.auditorUser
        alertData.time=checkItem.endOfAudit
        
      }

    })
    if(showalert && !this.state.showAlreadyDone.data && from==1 ){
      this.setState({showAlreadyDone:{from:2,alertData:alertData,show:true,data:[_dataToValidate, result, oldDataToValidate,from]},loading: false})
      return;
    }

    alldata.map((item) => {
      let createdAtUTC = moment.utc(item.created_at).valueOf()
      createdAtUTC = moment(createdAtUTC)
      item._createdAt = createdAtUTC.format('DD-MM-YYYY')
      item.createdAtTime = createdAtUTC.format('HH:mm')
      item.instantPhoto = item.instantPhoto
      item.oldDataToValidate = oldDataToValidate

      if (item.keyProctoring === _dataToValidate.keyProctoring) {
        let examnPadronInfo = JSON.parse(item.userData)
        let _key = 'id' + (item.padronInformation && item.padronInformation.r && item.padronInformation.i0 && item.padronInformation.i1 && item.padronInformation.ty ? item.padronInformation.n : examnPadronInfo.n)
        let i = _key
        let _type = this.obtenerTipo(item.padronInformation && item.padronInformation.r && item.padronInformation.i0 && item.padronInformation.i1 && item.padronInformation.ty ? item.padronInformation : examnPadronInfo)
        let _state = this.obtenerEstado(item.padronInformation && item.padronInformation.r && item.padronInformation.i0 && item.padronInformation.i1 && item.padronInformation.ty ? item.padronInformation : examnPadronInfo, _type)
        let _reg = this.fillComun(i, item.padronInformation && item.padronInformation.r && item.padronInformation.i0 && item.padronInformation.i1 && item.padronInformation.ty ? item.padronInformation : examnPadronInfo, item.request, _key, _state, _type)


        item.enroll = _reg;
        item.enrollExamn = examnPadronInfo ? examnPadronInfo : null;
        item.incidences = item.incidences || "[]"
        item.incidences = utilData.getIncidences(item)
        item.incidencesCounters = (item.incidencesCounters)
        item.systemReport = (item.systemReport)
        item.upload_end = item.upload_end
        item.upload_init = item.upload_init


        item.incidencesFiltered = utilData.prepareIncidences(item.incidences)
        item.incidencesTable = utilities.getIncidencesCounterExman(item.incidencesCounters ? item.incidencesCounters : "[]", item.incidences ? item.incidences.length : 0)
        res = item
      }
      else {
        item.incidences = item.incidences || "[]"
        item.incidences = utilData.getIncidences(item)
        item.incidencesCounters = (item.incidencesCounters)
        item.systemReport = (item.systemReport)
        item.upload_end = item.upload_end
        item.upload_init = item.upload_init


        item.incidencesFiltered = utilData.prepareIncidences(item.incidences)
        item.incidencesTable = utilities.getIncidencesCounterExman(item.incidencesCounters ? item.incidencesCounters : "[]", item.incidences ? item.incidences.length : 0)
        oldData.push(item)
      }


    })
    //  _dataToValidate.incidences = res.incidences || "[]"
    // _dataToValidate.incidences = utilData.getIncidences(_dataToValidate)
    _dataToValidate.incidences = res.incidences
    _dataToValidate._createdAt = res._createdAt

    _dataToValidate.createdAtTime = res.createdAtTime
    _dataToValidate.previous_attempts = oldData.length > 0 ? oldData : null
    _dataToValidate.actual_attempt = res
    _dataToValidate.oldDataToValidate = oldDataToValidate
    _dataToValidate.enroll = res.enroll;
    _dataToValidate.enrollExamn = res.enrollExamn;
    _dataToValidate.incidencesCounters = (res.incidencesCounters)
    _dataToValidate.systemReport = (res.systemReport)
    _dataToValidate.instantPhoto = res.instantPhoto

    _dataToValidate.upload_end = res.upload_end
    _dataToValidate.upload_init = res.upload_init
    _dataToValidate.incidencesFiltered = res.incidencesFiltered
    _dataToValidate.incidencesTable = res.incidencesTable
    if(res.finalResultAuditory){

      _dataToValidate.finalResultAuditory = res.finalResultAuditory
      _dataToValidate.endOfAudit=res.endOfAudit
      let _durationAuditory = "-";
      if (res.startOfAudit && res.startOfAudit !== null && res.endOfAudit && res.endOfAudit !== null) {
        let _start = moment(res.startOfAudit);
        let _end = moment(res.endOfAudit);
        let _diff = _end.diff(_start);
        _durationAuditory = moment.utc(_diff).format("HH:mm:ss");
      }
       _dataToValidate.auditTime=_durationAuditory
    }
    if(res.auditorUser){

      _dataToValidate.auditorUser = res.auditorUser
    }
    if(res.finalResultAuditoryDetail){

      _dataToValidate.finalResultAuditoryDetail = res.finalResultAuditoryDetail
    }
    // _dataToValidate.incidencesFiltered = utilData.prepareIncidences(_dataToValidate.incidences)
    // _dataToValidate.incidencesTable = utilities.getIncidencesCounterExman(_dataToValidate.incidencesCounters ? _dataToValidate.incidencesCounters : "[]", _dataToValidate.incidences ? _dataToValidate.incidences.length : 0)
    let row = _dataToValidate.enroll
    let rowExamn = _dataToValidate.enrollExamn

    let textdni=(row&& row.dni?row.dni: rowExamn && rowExamn.dni ?rowExamn.dni:'')||''
    let userve=(row&& row.ve?row.ve: rowExamn &&rowExamn.ve?rowExamn.ve:null)
    let textsex=(row && row.sexo? ((row.sexo === true) ? true : false):(( rowExamn&&rowExamn.sexo === true) ? true : false))
    let textname=(row && row.nombre? ((row.nombre === '-') ? (row.res === 'En espera') ? '' : '-' : row.nombre):((rowExamn&& rowExamn.nombre === '-') ? (rowExamn && rowExamn.res === 'En espera') ? '' : '-' : rowExamn && rowExamn.nombre?rowExamn.nombre:'' ))
    let textlastname =
      (
        row && row.apellido ?
          (
            (row.apellido === '-') ?
              (row.res === 'En espera') ?
                '' : '-'
              : row.apellido
          ) :
          (
            ( rowExamn && rowExamn.apellido === '-') ?
              (rowExamn.res === 'En espera') ?
                '' : '-'
              :
              rowExamn && rowExamn.apellido ?
                rowExamn.apellido : ''
          )
      )
    let textbirdate=row.fechanacimiento?(moment(row.fechanacimiento, 'x').format('YYYY-MM-DD')):rowExamn && rowExamn.fechanacimiento?(moment(rowExamn.fechanacimiento, 'x').format('YYYY-MM-DD')):(moment().format('YYYY-MM-DD'))
    // let textdni = (row && row.dni ? row.dni : rowExamn && rowExamn.dni ? rowExamn.dni : '') || ''
    // let userve = (row && row.ve ? row.ve : rowExamn && rowExamn.ve ? rowExamn.ve : null)
    // let textsex = (row && row.sexo ? ((row.sexo === true) ? true : false) : ((rowExamn && rowExamn.sexo === true) ? true : false))
    // let textname = (row && row.nombre ? ((row.nombre === '-') ? (row.res === 'En espera') ? '' : '-' : row.nombre) : ((rowExamn && rowExamn.nombre === '-') ? (rowExamn && rowExamn.res === 'En espera') ? '' : '-' : rowExamn && rowExamn.nombre ? rowExamn.nombre : ''))
    // let textlastname = (row && row.apellido ? ((row.apellido === '-') ? (row.res === 'En espera') ? '' : '-' : row.apellido) : ((rowExamn.apellido === '-') ? (rowExamn.res === 'En espera') ? '' : '-' : rowExamn && rowExamn.apellido ? rowExamn.apellido : ''))
    // let textbirdate = row.fechanacimiento ? (moment(row.fechanacimiento, 'x').format('YYYY-MM-DD')) : rowExamn && rowExamn.fechanacimiento ? (moment(rowExamn.fechanacimiento, 'x').format('YYYY-MM-DD')) : (moment().format('YYYY-MM-DD'))
    this.setState({
      startOfAudit: Date.now(),
      incidenceFilter: '',
      loading: false,
      auditResult: _dataToValidate.finalResultAuditory,
      showValidateForm: true,
      resultToExamine: { ..._dataToValidate, ...res },
      _resultToExamine: _dataToValidate,
      _2resultToExamine:_dataToValidate,
      textDni: textdni,
      _userVerify: userve,
      textBirDate: textbirdate,

      textSex: textsex,
      textName: textname,
      textLastName: textlastname,

      frenteDni: true,
      //textDescription: '',
      tabselected: _dataToValidate.previous_attempts ? _dataToValidate.previous_attempts.length : 0,
      buttonAccept: true,
      buttonDismiss: true,
      _buttonAccept: true,
      _buttonDismiss: true,
    })


  }
  validateActionButton(_dataToValidate,from) {
    this.setState({ loading: true, tabselected: 0 })
    const oldDataToValidate = JSON.parse(JSON.stringify(_dataToValidate))
    let myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + localStorage.fb_jwt);

    let formdata = new FormData();
    formdata.append("keyProctoring", _dataToValidate.keyProctoring);
    formdata.append("examID", _dataToValidate.examId);
    formdata.append("token", localStorage.back_jwt);
    formdata.append("attempt", _dataToValidate.attempt);
    formdata.append("idProctoring", _dataToValidate.id);
    formdata.append("user", _dataToValidate.user);
    formdata.append("start", Number(moment(this.state.searchStartDate).valueOf()));
    formdata.append("end", Number(moment(this.state.searchEndDate).endOf('day').valueOf()));

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
      body: formdata
    };

    let route = global.Direction_Back_panel + "/exam/" + _dataToValidate.examId
    if (false) {
      route = global.Direction_Back_panel + "/v2/validations/getExamDataV2"
    }

    fetch(route, requestOptions)
      .then(response => response.text())
      .then(result => {
        //alldata son todos los videos
        this.proccessExamnData(_dataToValidate, result, oldDataToValidate,from)
      })
      .catch((error) => this.setState({ loading: false }));
  }
  async updateValidationTable() {
    const _res=await db.updateValidationExamns(
      this.state.resultToExamine.keyProctoring,
      this.state.auditResult,
      this.state.auditText,
      this.state.startOfAudit
    )
      if(!_res.success && !_res.message.includes('Unauthorized')){
        this.setState({ showAlreadyDone: { show: true, from: 1, alertData:{user:_res.data.auditorUser,time:_res.data.endOfAudit} }, loading:false });
        return true
      }
    this.setState({
      startOfAudit: 0,
      auditResult: 1,
      auditText: '',
      showValidateForm: false,
      showProfileInfo: false,
    }, () => {
      this.setState({
        loading: true
      }, this.getExamns)
    })
  }

  async getExamnsToExport() {
    let _result = await db.getExamnsToExport(
      this.state.searchElectronicValidation,
      this.state.searchExamType,
      this.state.searchUserName,
      this.state.searchfile,
      this.state.searchSubject,
      this.state.searchStartDate,
      this.state.searchEndDate,
      this.state.searchdni,
      0, //me = 0 para examenes completados
      this.state.searchNickName,
      this.state.searchCanvasSubject,
      this.state.searchExamCanvas
    );

    _result = await utilData.prepareDataToExport(_result, "Examn");

    this.props.dataToExport(_result);
  }

  async getExamnsToLink() {
    let _result = await db.getExamnsToLink(this.state.searchElectronicValidation, this.state.searchExamType, this.state.searchUserName, this.state.searchfile, this.state.searchSubject, this.state.searchStartDate, this.state.searchEndDate, 0, this.state.pageNumber, this.state.order, this.state.column, this.state.searchdni, this.state.searchNickName, this.state.searchAudit, this.state.searchCanvasSubject, this.state.searchExamCanvas);

    _result = _result.link;
    window.open(_result);
    //this.props.offLoading()
    this.setState({ loading: false });
    //this.props.dataToExport(_result)
  }

  reset() {
    this.setState({
      searchUserName: "",
      searchdni: "",
      searchNickName: "",
      searchfile: "",
      searchSubject: [],
      // searchCanvasSubject: [],

      searchElectronicValidation: "Todas",
      searchExamType: [],
      searchAudit: "all",
      searchCanvasSubject: null,
      searchExamCanvas: null,
    });
  }

  processData(preResult) {
    let dataToTable = [];
    let dataByKey = {};
    let dataIncidenceByKey = {};

    let page = preResult.page;
    let totalRegistry = preResult.total;
    let totalProctoring = preResult._totalProctoring;


    let result = preResult.data;

    for (let i in result) {
      if (result[i].finalizatedAt) {
        let _obj = {};


        let texto = result[i].subjectName
        let limiteCaracteres = 12

        function limitarCaracteres(texto, limiteCaracteres) {
          // if (texto.length > limiteCaracteres) {
          //   return texto.substring(0, limiteCaracteres) + '...';
          // } else {
          return texto && <Tooltip placement="top" title={result[i].subjectName}></Tooltip>;
          // }
        }


        //Para Tabla principal
        //_obj.name = (result[i].userName ? result[i].userName : "A-").replace(/\"/g, "")
        _obj.name = (result[i].userName ? result[i].userName : "A-").replace(/["']/g, "");
        _obj.dateStart = result[i].createdAt ? moment(result[i].createdAt).format(moment.HTML5_FMT.TIME) : "";
        _obj.dateEnd = result[i].finalizatedAt ? moment(result[i].finalizatedAt).format(moment.HTML5_FMT.TIME) : "";
        _obj.date = result[i].createdAt ? moment(result[i].createdAt).format("DD/MM/YYYY") : "";
        _obj.time = result[i].createdAt;
        _obj.score = result[i].qualification ? result[i].qualification : 0;
        _obj.keyProctoring = result[i].keyProctoring;
        _obj.subjectName = result[i].subjectName ? result[i].subjectName : "-";
        // _obj.subjectName = result[i].subjectName ? limitarCaracteres(texto, limiteCaracteres) : "-"
        _obj.id = result[i].id;
        _obj.searchdni = result[i].searchdni;
        _obj.key = i;
        _obj.finalResultAuditory = result[i].finalResultAuditory || result[i].finalResultAuditory === 0 ? result[i].finalResultAuditory : -1;
        _obj.searchNickName = result[i].searchNickName;
        _obj.ve = result[i].ve ? result[i].ve : 0;
        _obj.validation = _obj.ve;

        dataToTable.push(_obj);

        //Para Tabla de incidencias
        let _incidendeObj = [];

        if (result[i].incidences) {
          let _incidences = JSON.parse(result[i].incidences);
          //this.state.validationSelect

          for (let e in _incidences) {
            let _incidence = {};
            _incidence.number = 1;
            _incidence.key = 1;
            _incidence.anomaly = _incidences[e].detail;
            _incidence.type = _incidences[e].criticality === 1 ? "Baja" : _incidences[e].criticality === 2 ? "Media" : _incidences[e].criticality === 3 ? "Alta" : _incidences[e].criticality === 4 ? "Crítica" : "-";

            if (_incidences[e].origin) {
              if (_incidences[e].origin === 1) {
                _incidence.origin = "Imagen";
              } else if (_incidences[e].origin === 2) {
                _incidence.origin = "";
              }
            }

            if (_incidences[e].time) {
              let start = moment(result[i].createdAt);
              let end = moment(parseInt(_incidences[e].time));
              let diff = end.diff(start);
              let f = moment.utc(diff).format("HH:mm:ss");
              _incidence.time = f.toString() + " hs";
              _incidence.diff = diff;
              _incidence.button = diff;
            }

            _incidendeObj.push(_incidence);
          }
        } else {
          if (result[i].tdi) {
            this.processValidationInsidence(result[i].tdi, result[i].createdAt, _incidendeObj);
          }

          if (result[i].tui) {
            this.processValidationInsidence(result[i].tui, result[i].createdAt, _incidendeObj);
          }

          if (result[i].tlri) {
            this.processValidationInsidence(result[i].tlri, result[i].createdAt, _incidendeObj);
          }

          if (result[i].fri) {
            this.processValidationInsidence(result[i].fri, result[i].createdAt, _incidendeObj);
          }

          if (result[i].nfi) {
            this.processValidationInsidence(result[i].nfi, result[i].createdAt, _incidendeObj);
          }

          if (result[i].mfi) {
            this.processValidationInsidence(result[i].mfi, result[i].createdAt, _incidendeObj);
          }

          if (result[i].sci) {
            this.processValidationInsidence(result[i].sci, result[i].createdAt, _incidendeObj);
          }
        }

        _incidendeObj = _incidendeObj.sort((a, b) => a.diff - b.diff);

        let _num = 1;
        for (let f in _incidendeObj) {
          _incidendeObj[f].number = _num;
          _incidendeObj[f].key = _num;
          _num++;
        }

        dataIncidenceByKey[result[i].keyProctoring] = _incidendeObj;

        dataByKey[result[i].keyProctoring] = result[i];

        if (dataByKey[result[i].keyProctoring].startOfAudit != null && dataByKey[result[i].keyProctoring].finalizatedAt != null) {
          let start = moment(dataByKey[result[i].keyProctoring].startOfAudit);
          let end = moment(dataByKey[result[i].keyProctoring].endOfAudit);
          let diff = end.diff(start);
          let t = moment.utc(diff).format("HH:mm:ss");
          dataByKey[result[i].keyProctoring].auditTime = t;
        }
      }
    }

    //this.props.dataResult(dataToTable, dataByKey, dataIncidenceByKey, page, totalRegistry)
    //this.props.showLoading(false)

    this.setState({
      resultToTable: dataToTable,
      pageNumber: page,
      totalRegistry: totalRegistry,
      totalProctoring: totalProctoring,
      orderChange: false,
      loading: true,
    });
  }

  processValidationInsidence(data, createdAt, pushTo) {
    let _data = JSON.parse(data);

    for (let o in _data) {
      let obj = {};
      obj.number = 1;
      obj.anomaly = _data[o].d;
      obj.origin = "Imagen";
      obj.type = _data[o].c === 1 ? "Baja" : _data[o].c === 2 ? "Media" : _data[o].c === 3 ? "Alta" : _data[o].c === 4 ? "Crítica" : "-";

      if (_data[o].t) {
        let start = moment(createdAt);
        let end = moment(parseInt(_data[o].t));
        let diff = end.diff(start);
        let f = moment.utc(diff).format("HH:mm:ss");
        //obj.time = _data[o].t ? f + '' + ' hs' : 0
        //obj.diff = _data[o].t ? f + '' + ' hs' : 0
        obj.time = f.toString() + " hs";
        obj.diff = f.toString() + " hs";
        obj.button = diff;
      }
      pushTo.push(obj);
    }
  }

  handleChange = async (event, newValue) => {
    console.log(newValue)
    this.setState({ tabselected: newValue })
    let data = { ...this.state._resultToExamine }

    let newData
    if (newValue == data.previous_attempts.length) {
      newData = { ...data, ...data.actual_attempt };

    } else {

      newData = { ...data, ...data.previous_attempts[newValue] };
    }
    newData = await utilData.prepareExamnsToTable(newData);
    this.setState({ resultToExamine: newData });
    this.setState({ _2resultToExamine: newData });
  };



  obtenerTipo(data) {
    if (data.ty + '' === "1") {
      return "Auto"
    }
    else if (data.ty + '' === "2") {
      return "Manual"
    }
    else if (data.ty + '' === "3") {
      return "Manual"
    }
    else {
      return "Incompleto"
    }


  }

  obtenerEstado(_data, _type) {
    const _r = _data.r + ""
    const _ty = _data.ty + ""
    if (_ty === "1" && _r === "1") {
      return "Aprobada"
    }
    switch (_r) {
      case "0":
        return "En espera"
      case "1":
        return "Aprobada"

      case "2":
        return "Rechazada"
      default:
        return ""
    }
    return

  }


  fillComun(_i, _data, _request, _key, _state, _type) {

    let _reg

    let _apellido = _data.d ? _data.d.s ? _data.d.s : '' : ''
    let _nombre = _data.d ? _data.d.n ? _data.d.n : '' : ''

    //let _date = Number(_data.t ? _data.t : _data.d ? _data.d.t ? _data.d.t : moment().format("x") : moment().format("x"))
    let _date = Number(_data.ct ? _data.ct : moment().format("x"))


    let _fn = _data ? _data.d ? _data.d.b ? moment(_data.d.b, 'DD/MM/YYYY').format('x') : moment().format('x') : moment().format('x') : moment().format('x')

    let _motivo = _request ? _request.d ? _request.d : '' : ''

    let _me = _data ? _data.me ? _data.me === "success" ? 1 : 0 : 0 : 0
    let _ve = (_data && _data.ve) ? _data.ve : null
    let _img0

    let _img1
    if ((_data.ty + '' === '2' || _data.ty + '' === '3') && (_data.r + "" === "0" || _data.r + "" === "2")) {


      _img0 = _request && _request.i ? _request.i : ''
      _img1 = _request && _request.i0 ? _request.i0 : ''
    }
    else {
      _img0 = _data.i0 && _data.i0.i ? _data.i0.i : ''
      _img1 = _data.i1 && _data.i1.i ? _data.i1.i : ''
    }
    console.log(JSON.stringify((_data.ty + '' === '2' || _data.ty + '' === '3') && (_data.r + "" === "0" || _data.r + "" === "2")), _data.i1)
    let _firstTimeUser = Number(_data.ct ? _data.ct : _data.t)
    let _lastChangeUser = Number(_data.ad ? _data.ad : _firstTimeUser)
    let _lastChangeUni = Number(_data.la ? _data.la : _lastChangeUser)


    _reg = {
      _lastChangeUser: moment(_lastChangeUser).format("DD/MM/YYYY"),
      _firstTimeUser: moment(_firstTimeUser).format("DD/MM/YYYY"),
      _lastChangeUni: moment(_lastChangeUni).format("DD/MM/YYYY"),
      key: _i,
      fbkey: _key,
      d: _data.d,
      userid: _key,
      user: _data.n,
      name: (_apellido === '') ? (_nombre === '') ? '' : _nombre : (_nombre === '') ? _apellido : _apellido + ', ' + _nombre,
      apellido: _apellido,
      nombre: _nombre,
      sexo: _data.d ? _data.d.g ? (_data.d.g === 'F') ? true : false : false : false,
      fechanacimiento: Number(_fn),
      dni: _data.d ? _data.d.dni ? _data.d.dni : '' : '',
      date: moment(_date).format("DD/MM/YYYY"),
      datetimestamp: _date,
      res: _state,
      det: _data.det,
      ty: _data.ty,
      userimg: _img0,
      dniimg1: _img1,
      dniimg2: _data.i2 ? _data.i2.i ? _data.i2.i : '' : '',
      tipo: _type,
      r: _data.r ? Number(_data.r) : 0,
      motivo: _motivo,
      me: _me,
      ve: _ve,

    }
    return _reg

  }


  render() {
    const { classes } = this.props;

    return (
      <div style={{ width: "100%", height: "100%" }}>
        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={this.state.showAlreadyDone.show}
          style={{alignContent:"center"}}
          onClose={() => {
            this.setState({ showAlreadyDone: { show: false, from: 0, alertData:{} } });
          }}>
          <Paper elevation={3} 
          style={{
            position: "absolute",
          width:"470px",

            // border: '2px solid #000',
            top: "50%",
            transform: "translate(-50%, -50%)",
            left: "50%",
            overflow: "hidden",
            padding:'25px'
          }}
          >
            <IconButton onClick={() => {
              this.setState({ showAlreadyDone: { show: false, from: 0, alertData:{} } });
            }} style={{ position: "absolute", top: 10, right: 10 }} aria-label="delete">
              <CloseIcon />
            </IconButton>
            <p style={{ 
            // fontFamily: "DINPro Medium",
            fontWeight: '500', 
            paddingBottom:"5px",
             fontSize: '25px', color: "#404040" }}>
              Esta sesión ya fue auditada
            </p>
            <p style={{ fontWeight: '500', 
            // fontFamily: "DINPro Medium",
            paddingBottom:"10px",

             fontSize: '16px', color: "#009EFD" }}>
              Revisada por {this.state.showAlreadyDone.alertData.user} el {moment(+(this.state.showAlreadyDone.alertData.time)).format('DD/MM/YYYY [a las] hh:mm [hs.]')}  
            </p>
            {this.state.showAlreadyDone.from == 1 ?
              <div>
                <p style={{ 
                  fontWeight: '300',
            paddingBottom:"5px",

                //  fontFamily: "DINPro Medium",
                  fontSize: '18px', color: "#2D2D2D" }}>
                  Actualiza la página para ver el resultado
                </p>
              </div>
              :
              <div>
                <p style={{ fontWeight: '300', 
                // fontFamily: "DINPro Medium",
            paddingBottom:"15px",

                fontSize: '18px', color: "#2D2D2D" }}>
                  Puedes visualizar el examen pero no podrás modificar el resultado de la auditoría
                </p>
              </div>
            }
            {this.state.showAlreadyDone.from == 2 ?
              <div>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <ColorButtonOutlined onClick={() => {
                      this.setState({ showAlreadyDone: { show: false, from: 0, alertData:{} } });
                    }} variant="outlined" style={{width:'100%'}}>Cerrar</ColorButtonOutlined>
                  </Grid>
                  <Grid item xs={6}>
                    <ColorButton onClick={() => {
                      let data= this.state.showAlreadyDone.data
                      this.proccessExamnData(data[0],data[1],data[2],data[3])
                      this.setState({ showAlreadyDone: { show: false, from: 0, alertData:{} } });
                    }} variant="contained" style={{width:'100%'}} >Ver examen</ColorButton>
                  </Grid>
                </Grid>
              </div>
              :
              null
            }
          </Paper>
          {/* <AlreadyDone from={this.state.showAlreadyDone.from} dismiss={() => {
            this.setState({ showAlreadyDone: { show: false, from: 0 } });
          }}
            continue={() => {
              this.setState({ showAlreadyDone: { show: false, from: 0 } });
            }}></AlreadyDone> */}
        </Modal>
        <Modal
          open={this.state.showModalMassiveNotif}
          onClose={() => {
            this.setState({ showModalMassiveNotif: false });
          }}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <Paper
            elevation={2}
            style={{
              position: "absolute",
              width: 400,
              // border: '2px solid #000',
              borderRadius: 15,
              top: "50%",
              transform: "translate(-50%, -50%)",
              bgcolor: "background.paper",
              left: "50%",
              boxShadow: 24,
              overflow: "hidden",
            }}
          >
            <div style={{ backgroundColor: "#0bb4e4", padding: "32px 16px 8px 16px" }}>
              <h3 id="simple-modal-title" style={{ marginTop: 0, color: "white", fontWeight: "bold" }}>
                Enviar Notificación
              </h3>

              <p style={{ color: "white" }} id="simple-modal-description">
                Se enviará la siguiente notificación, mediante la App Móvil de Klarway, a alumnos que hayan realizado su examen y su guardado no haya finalizado.
              </p>
            </div>
            <div style={{ padding: "16px 16px 17px 16px" }}>
              <p id="simple-modal-description">"Para terminar de procesar tu examen, debes abrir Klarway desde el dispositivo en el que lo realizaste, y verificar que se complete al 100% la carga. Gracias!"</p>

              <div style={{ display: 'flex', justifyContent: 'end', padding: '16px 0px 0px 0px' }}>
                <Button onClick={() => {
                  this.setState({ showModalMassiveNotif: false })
                }} >Cancelar</Button>
                <Button style={{ color: 'rgb(11, 180, 228)' }} onClick={() => {
                  let myHeaders = new Headers();
                  myHeaders.append("Cookie", "adonis-session=574bc63e80dcd35ed64fbd13213bf3af1yJmBatIsHfmKQaFlZs9hN78WMe00FljraU8JvNHex4YHxE8uH97g2iC9gUJa3CiJKnETZwxHewLp1z%2FeVEN%2FvxldN5iBOaN6nM6BFw%2BiMqjiCkS%2BAOndwLZd7ZENx5C; adonis-session-values=5911a7a675361d4a20c57683c238591bR1hAEP2Rio8hj%2F68jKsKTt3viKwmthIs991HD4xajKz7OBrjZNjjgWA2uuskwJjHQpY25BAweElG9biVZX6qHw%3D%3D");

                  let requestOptions = {
                    method: 'GET',
                    headers: myHeaders,
                    redirect: 'follow'
                  };

                  fetch(global.Direction_Back_panel + "/notifications/masiveSend", requestOptions)
                    .then(response => response.text())
                    .then(result => {
                      this.setState({ showModalMassiveNotif: false })
                    })
                    .catch(error => console.log('error', error));
                }} >Enviar</Button>
              </div>
            </div>
          </Paper>
        </Modal>
        {this.state.loading ? (
          <div style={{ position: "fixed", left: 0, top: 0, backgroundColor: "#ffffff", opacity: "0.7", display: "flex", alignItems: "center", justifyContent: "center", width: "100%", height: "100%", zIndex: 9999 }}>
            <CircularProgress />
          </div>
        ) : (
          <div />
        )}
        <GridContainer>
          <GridItem xs={12} style={{ marginTop: -70 }}>
            <Card>
              <CardHeader color="lightBlueBiv" icon>
                <GridContainer style={{ paddingRight: 0 }}>
                  <GridItem xs={12} sm={6} md={4} lg={4} xl={4}>
                    <CardIcon color="lightBlueKW">
                      <Assignment />
                    </CardIcon>
                    <h4 className={classes.cardIconTitle} style={{ color: "dimgray" }}>
                      {languages[lang].examsNA_examsPerformed }
                    </h4>
                  </GridItem>

                  <GridItem xs={12} sm={6} md={8} lg={8} xl={8} style={{ width: "100%", marginTop: 20, display: "flex", justifyContent: "flex-end" }}>
                    <div style={{ marginRight: 5, marginTop: 10 }}>
                      <BivExportCsvButton
                        active={true}
                        csvData={[]}
                        fileName="klarway-initial-validations"
                        customFunction={true}
                        onClickCustom={() => {
                          this.setState(
                            {
                              loading: true,
                            },
                            this.getExamnsToLink
                          );
                        }}
                      />
                    </div>
                  </GridItem>
                </GridContainer>
              </CardHeader>

              <CardBody>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  {/* Filtros */}
                  <BivProctoringFilter
                    search={() => {
                      this.setState(
                        {
                          pageNumber: 1,
                          loading: true,
                        },
                        this.getExamns
                      );
                    }}
                    searchStartDate={this.state.searchStartDate}
                    changeStartDate={(value) => {
                      this.setState({
                        searchStartDate: value,
                      });
                    }}
                    searchEndDate={this.state.searchEndDate}
                    changeEndDate={(value) => {
                      this.setState({
                        searchEndDate: value,
                      });
                    }}
                    searchUserName={this.state.searchUserName}
                    changeUserName={(value) => {
                      this.setState({
                        searchUserName: value,
                      });
                    }}
                    searchdni={this.state.searchdni}
                    changedni={(value) => {
                      this.setState({
                        searchdni: value,
                      });
                    }}
                    searchNickName={this.state.searchNickName}
                    changeNickName={(value) => {
                      this.setState({
                        searchNickName: value,
                      });
                    }}
                    searchfile={this.state.searchfile}
                    changefile={(value) => {
                      this.setState({
                        searchfile: value,
                      });
                    }}
                    searchSubject={this.state.searchSubject}
                    searchCanvasSubject={this.state.searchCanvasSubject}
                    changeSubject={(value) => {
                      if (global._lms_ === '4') {
                        this.setState({
                          searchCanvasSubject: value,
                        });
                      } else {
                        this.setState({
                          searchSubject: value,
                        });
                      }
                    }}
                    searchElectronicValidation={this.state.searchElectronicValidation}
                    changeElectronicValidation={(value) => {
                      this.setState({
                        searchElectronicValidation: value,
                      });
                    }}
                    searchExamType={this.state.searchExamType}
                    changeExamType={(value) => {
                      this.setState({
                        searchExamType: value,
                      });
                    }}
                    searchAudit={this.state.searchAudit}
                    changeAudit={(value) => {
                      this.setState({
                        searchAudit: value,
                      });
                    }}
                    canvasSubjects={this.state.canvasSubjects}
                    subjects={this.state.subjects}
                    typeOfExams={this.state.typeOfExams}
                    searchExamCanvas={this.state.searchExamCanvas}
                    changeExamCanvas={(value) => {
                      this.setState({
                        searchExamCanvas: value,
                      });
                    }}
                    resetFields={() => {
                      this.reset();
                    }}

                  /*
                pageNumber={this.state.pageNumber}
                orderChange={this.state.orderChange}
                order={this.state.order}
                column={this.state.column}
               
                dataResult={(_dataToTable, _page, _totalRegistry) => {
                  this.setState({
                    resultToTable: _dataToTable,
                    pageNumber: _page,
                    totalRegistry: _totalRegistry,
                    orderChange: false,
                    loading: true,
                  })
                }}
                toExport={this.state.toExport}
                dataToExport={(_validationTableForExport) => {
                  this.setState({
                    toExport: false,
                    loading: false,
                  }, () => {
                    //FileSaver.saveAs(_validationTableForExport);
                    BivExportCsvFunction(_validationTableForExport, 'klarway-validations')
                  })
                }}
                offLoading={() => {
                  this.setState({
                    toExport: true,
                    loading: false,
                  })
                }}
               
              
                */
                  />

                  <BivDivider />

                  <GridContainer>
                    <GridItem xs={12} sm={12} md={8} lg={8} xl={8} style={{ display: "flex", alignItems: "left", justifyContent: "flex-start", width: "100%" }}>
                      <div style={{ display: "flex", alignItems: "left", justifyContent: "flex-start", paddingLeft: 20 }}>
                        <Typography variant="subtitle1" style={{ fontSize: 12 }} gutterBottom>{"Exámenes: "}</Typography>
                        <Typography variant="subtitle2" style={{ fontSize: 13, paddingLeft: 20 }} gutterBottom>{this.state.totalRegistry}</Typography>
                      </div>
                      {
                        this.state.totalProctoring && this.state.isAdmin ?
                          <div style={{ display: "flex", alignItems: "left", justifyContent: "flex-start", paddingLeft: 20 }}>
                            <Typography variant="subtitle1" style={{ fontSize: 12, paddingLeft: 20 }} gutterBottom>{"Sesiones monitoreadas: "}</Typography>
                            <Typography variant="subtitle2" style={{ fontSize: 13, paddingLeft: 20 }} gutterBottom>{this.state.totalProctoring}</Typography>
                          </div> : null
                      }
                    </GridItem>

                    <GridItem xs={12} sm={12} md={4} lg={4} xl={4} style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", width: "100%" }}>
                      {/* <CustomButton
                        style={{ color: '#FFFFFF', backgroundColor: '#0bb4e4', height: 35, marginRight: 15 }}
                        size="sm"
                        onClick={() => {
                          this.setState({
                            showModalMassiveNotif: true
                          })
                        }}
                      >
                        <div style={{ width: "100%", paddingLeft: 5 }}>
                          {(<NotificationImportantIcon />)}
                        </div>
                      </CustomButton> */}
                      <KWZoomButton
                        onClick={() => {
                          if (this.state.tableZoom < 5) {
                            this.setState({
                              tableZoom: this.state.tableZoom + 1,
                            });
                          }
                        }}
                        active={this.state.tableZoom < 5 ? true : false}
                        text={"-"}
                      />

                      <KWZoomButton
                        onClick={() => {
                          if (this.state.tableZoom > 1) {
                            this.setState({
                              tableZoom: this.state.tableZoom - 1,
                            });
                          }
                        }}
                        active={this.state.tableZoom > 1 ? true : false}
                        text={"+"}
                      />
                    </GridItem>
                  </GridContainer>

                  {/* Table
                   */}

                  <BivProctoringTable
                    style={{ verticalAlign: 'middle' }}
                    //id='__laTabla__'
                    zoom={this.state.tableZoom}
                    data={this.state.resultToTable}
                    getColumnData={columnTable.getTableExmanF13(this.sortFunction.bind(this), this.formatWithIconSee.bind(this))}
                    pageNumber={this.state.pageNumber}
                    pageNumberChange={(value) => {
                      this.setState(
                        {
                          loading: true,
                          pageNumber: value,
                        },
                        this.getExamns
                      );
                    }}
                    totalRegistry={this.state.totalRegistry}
                    textNoData={languages[lang].examsNA_noValidationsCUFTGResults}
                  />
                </div>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>

        <Dialog
          open={this.state.showValidateForm}
          //maxWidth={true}
          fullScreen={true}
          // style={{ padding: 30 }}
          keepMounted
          disableBackdropClick={true}
          onClose={(value) => {
            this.setState({
              showValidateForm: value,
            });
          }}
          aria-labelledby="info-slide-title"
          aria-describedby="info-slide-description"
        >
          <DialogTitle
            id="info-slide-title"
            disableTypography
            style={{ marginTop: 0, marginBottom: 0, paddingTop: 0, paddingBottom: 0 }}
          >


            <GridContainer>

              <GridItem xs={10} sm={10} md={10} lg={10} xl={10} style={{}} >
                <h4 >{languages[lang].examsNA_examsInformation}</h4>
              </GridItem>


              {/*Boton Historial*/}
              {/* <GridItem xs={2} sm={2} md={2} lg={2} xl={2} style={{ textAlign: 'end', width: "100%", display: "flex", justifyContent: "flex-end", alignItems: 'center' }}>

                <Button color="success" style={{ color: "#FFF", backgroundColor: "#0bb4e4" }} onClick={() => {
                  this.setState({ viewActions: true })
                }}><HistoryIcon />HISTORIAL</Button>
                <Modal
                  open={this.state.viewActions}
                  onClose={() => {
                    this.setState({ viewActions: false });
                  }}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box style={{
                    position: 'absolute',
                    right: '0%',
                    // transform: 'translate(-50%, -50%)',
                    width: '50%',
                    height: '100%',
                    backgroundColor: '#f8f9fa',
                    // boxShadow: 24,
                    p: 4,
                  }}>
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        this.setState({ viewActions: false });
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                    <ActionInformation examData={this.state.resultToExamine}></ActionInformation>
                  </Box>
                </Modal>


              </GridItem> */}




              <GridItem xs={2} sm={2} md={2} lg={2} xl={2} style={{ width: "100%", display: "flex", justifyContent: "flex-end", alignItems: 'flex-end', }} >

                {this.state.resultToExamine ?
                  <IconButton
                    aria-expanded={true}
                    aria-label="show more"
                    style={{ color: "#f11104", outline: "none" }}
                    // disabled={!this.state.resultToExamine.previous_attempts}
                    onClick={() => {
                      this.setState(
                        {
                          loading: true,
                        },
                        utilities.exportExamnToPdf(this.state.resultToExamine, this.uni, () => {
                          this.setState({
                            loading: false,
                          });
                        })
                      );
                    }}
                  >
                    <PictureAsPdfIcon fontSize="large" />
                  </IconButton>
                  : null}
                {/* resultToExamine.previous_attepts */}
                <CustomButton
                  justIcon
                  //style={{ position: 'absolute', right: 30 }}
                  key="close"
                  aria-label="Close"
                  color="transparent"
                  onClick={() => {
                    if (document.getElementById("video")) {
                      document.getElementById("video").pause();
                    }
                    this.setState({
                      showValidateForm: false,
                    });
                  }}
                >
                  <Close fontSize="large" />
                </CustomButton>
              </GridItem>
            </GridContainer>





            <GridContainer>

              <GridItem xs={12} sm={12} md={12} lg={12} xl={12} style={{ display: "flex", flexDirection: "row", marginTop: 0, marginBottom: 0, }}>

                {this.state._resultToExamine.previous_attempts ?
                  <div style={{
                    paddingLeft: 0,
                    paddingRight: 15,
                    width: '100%'
                  }}>
                    <div style={{
                      backgroundColor: "#eaeaea", flexGrow: 1,
                      width: '100%',
                      borderRadius: 10
                    }}>
                      <Card position="static" color="default" >
                        <StyledTabs value={this.state.tabselected} onChange={this.handleChange} >
                          {this.state._resultToExamine && this.state._resultToExamine.previous_attempts && this.state._resultToExamine.previous_attempts.map((a, b) => (
                            <StyledTab key={b} n={b} active={this.state.tabselected == b ? 'selected' : 'not'} label={<div>{this.state._resultToExamine.previous_attempts[b]._createdAt}<br />{this.state._resultToExamine.previous_attempts[b].createdAtTime}</div>}> </StyledTab>
                          ))}
                          <StyledTab active={this.state.tabselected == this.state._resultToExamine.previous_attempts.length ? 'selected' : 'not'} n={this.state._resultToExamine.previous_attempts.length} label={<div>{this.state._resultToExamine._createdAt}<br />{this.state._resultToExamine.createdAtTime}</div>} />

                        </StyledTabs>
                      </Card>
                    </div>
                  </div>
                  : null
                }
              </GridItem>

              {/* <GridItem xs={10} sm={10} md={10} lg={10} xl={10} style={{}} >
                <h4 >{languages[lang].examsNA_examsInformation}</h4>
              </GridItem> */}

              {/* <GridItem xs={2} sm={2} md={2} lg={2} xl={2} style={{ width: "100%", display: "flex", justifyContent: "flex-end", alignItems: 'flex-end' }} > */}

              {/* {this.state.resultToExamine ? */}
              {/* <IconButton */}
              {/* aria-expanded={true} */}
              {/* aria-label="show more"
                    style={{ color: "#f11104" }}
                    // disabled={!this.state.resultToExamine.previous_attempts}
                    onClick={() => {
                      this.setState({
                        loading: true
                      }, utilities.exportExamnToPdf(this.state.resultToExamine, this.uni, () => {
                        this.setState({
                          loading: false
                        })
                      })
                      )
                    }}
                  >
                    < PictureAsPdfIcon fontSize="large" />
                  </IconButton>
                  :
                  null} */}
              {/* resultToExamine.previous_attepts */}
              {/* <CustomButton
                  justIcon
                  //style={{ position: 'absolute', right: 30 }} */}
              {/* key="close"
                  aria-label="Close"
                  color="transparent"
                  onClick={() => {
                    if (document.getElementById('video')) {
                      document.getElementById('video').pause();
                    }
                    this.setState({
                      showValidateForm: false
                    })
                  }}
                >
                  <Close fontSize="large" />
                </CustomButton>
              </GridItem> */}

            </GridContainer>
          </DialogTitle>

          <DialogContent id="info-slide-description">
            {/* {this.state.resultToExamine.finalResultAuditory ===-1 ?  */}
            <Card style={{ marginTop: 0 }} fullWidth={true} maxWidth={"md"}>

              {this.state.resultToExamine.enroll && this.state.resultToExamine.enroll.ty && this.state.resultToExamine.enroll.r ?
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12} lg={12} xl={12} style={{ margin: 10 }}>

                    {(this.state.resultToExamine.enroll.ve && this.state.resultToExamine.enroll.ve !== 1) || !this.state.resultToExamine.enroll.ve && this.state.resultToExamine.finalResultAuditory === -1 ?

                      <GridContainer style={{ marginTop: 0, paddingLeft: 12 }}>


                        <GridItem xs={10} sm={10} md={10} lg={10} xl={10} >
                          <Box style={{
                            marginTop: 10, marginLeft: 5, marginBottom: 5, backgroundColor: '#f55451',
                            color: 'white', border: 1, p: 1, borderRadius: 6, alignContent: 'center', padding: 5, width: 'fit-content'
                          }}>
                            <ErrorOutlineOutlinedIcon /> {!this.state.resultToExamine.enroll.ve ? "Registro no verificado" : "Registro rechazado"}
                          </Box>
                        </GridItem>

                        {/* <GridItem xs={9} sm={9} md={9} lg={10} xl={10} style={{}}>
                      <h4 style={{ color: "dimgray" }}>Perfil de empadronamiento</h4>
                    </GridItem> */}

                        <GridItem xs={2} sm={2} md={2} lg={2} xl={2} style={{ width: "100%", position: "float", display: "flex", justifyContent: "flex-end", alignItems: "flex-end" }}>
                          <IconButton
                            aria-expanded={this.state.showVe}
                            aria-label="show more"
                            onClick={() => {
                              this.setState({
                                showVe: !this.state.showVe,
                              });
                            }}
                          >
                            {this.state.showVe ? <ExpandLess /> : <ExpandMoreIcon />}
                          </IconButton>
                        </GridItem>
                      </GridContainer>

                      :

                      <GridContainer style={{ marginTop: 0, paddingLeft: 10 }}>

                        <GridItem xs={9} sm={9} md={9} lg={10} xl={10} style={{}}>
                          <h4 style={{ color: "dimgray" }}>Registro</h4>
                        </GridItem>

                        <GridItem xs={3} sm={3} md={3} lg={2} xl={2} style={{ width: "100%", position: "float", display: "flex", justifyContent: "flex-end", alignItems: "flex-end" }}>
                          <IconButton
                            aria-expanded={this.state.showVe}
                            aria-label="show more"
                            onClick={() => {
                              this.setState({
                                showVe: !this.state.showVe,
                              });
                            }}
                          >
                            {this.state.showVe ? <ExpandLess /> : <ExpandMoreIcon />}
                          </IconButton>
                        </GridItem>
                      </GridContainer>

                    }

                    {/* {(this.state.resultToExamine.enroll.ve && this.state.resultToExamine.enroll.ve !== 1) || !this.state.resultToExamine.enroll.ve && this.state.resultToExamine.finalResultAuditory === -1 ? */}
                    {/* : */}



                    <Collapse in={!this.state.showVe} style={{ padding: 20 }} timeout="auto" unmountOnExit>

                      <GridContainer>
                        <GridItem xs={12} sm={12} md={12} lg={12} xl={12} style={{ marginTop: 0, marginBottom: 15 }}>
                          {this.state.resultToExamine.enroll.tipo === "Manual" ? <p style={{ color: "#0bb4e4", fontSize: 12, fontWeight: "bold" }}>REGISTRO MANUAL</p> : <p style={{ color: "#0bb4e4", fontSize: 12, fontWeight: "bold" }}>REGISTRO AUTOMATICO</p>}
                        </GridItem>
                      </GridContainer>

                      {this.state.resultToExamine.enroll.tipo === "Manual" ? (
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={12} lg={12} xl={12} style={{ marginTop: -20, marginBottom: 0 }}>
                            <Card style={{ marginTop: 0 }}>
                              <h4 style={{ paddingLeft: "20px", color: 'black' }}>Comentario del alumno:<span style={{ color: 'dimgray' }}>{this.state.resultToExamine.enroll.motivo} </span> </h4>
                            </Card>
                          </GridItem>
                        </GridContainer>
                      ) : (
                        <div />
                      )}


                      <GridContainer style={{ marginTop: 0, paddingLeft: 10 }}>
                        <GridContainer>

                          {/* Foto de Usuario */}
                          <GridItem xs={12} sm={6} md={3} lg={3} xl={3} style={{ marginBottom: 0, marginTop: -20, height: "400px" }}>
                            <Card style={{ marginTop: 0 }}>
                              <CardHeader style={{ marginBottom: 0 }}>
                                <h4 style={{ color: "dimgray" }}>Foto del usuario</h4>
                              </CardHeader>

                              <CardBody style={{ margin: 0 }}>
                                {this.state.resultToExamine.enroll.userimg === "" ? (
                                  <div style={{ height: "300px", display: "flex", flexDirection: "column", textAlign: "center", justifyContent: "center", backgroundColor: "#f7f7f7", fontSize: 16, fontWeight: 400, color: "#8c8a88" }}>No tiene</div>
                                ) : (
                                  <Image
                                    style={{ maxHeight: "300px" }}
                                    src={this.state.resultToExamine.enroll.userimg}
                                    rounded
                                    fluid
                                    onClick={() => {
                                      this.setState({ photoSelected: 0, photoDialog: true });
                                    }}
                                  />
                                )}
                              </CardBody>
                            </Card>
                          </GridItem>
                          {/* Foto de Dni */}
                          <GridItem xs={12} sm={6} md={3} lg={3} xl={3} style={{ marginBottom: 0, marginTop: -20, height: "400px" }}>
                            <Card style={{ marginTop: 0 }}>
                              <CardHeader>
                                <h4 style={{ color: "dimgray" }}>Foto de {global.dni_name}</h4>
                              </CardHeader>

                              <CardBody style={{ margin: 0 }}>
                                {this.state.frenteDni ? (
                                  this.state.resultToExamine.enroll.dniimg1 === "" ?
                                    <div style={{ height: "260px", display: "flex", flexDirection: "column", textAlign: "center", justifyContent: "center", backgroundColor: "#f7f7f7", fontSize: 16, fontWeight: 400, color: "#8c8a88" }}>No tiene</div>
                                    :
                                    //max-height: 50vw;
                                    <Image
                                      style={{ maxHeight: "260px", display: "flex" }}
                                      src={this.state.resultToExamine.enroll.dniimg1}
                                      rounded
                                      fluid
                                      onClick={() => {
                                        this.setState({ photoSelected: 1, photoDialog: true });
                                      }}
                                    />

                                ) : this.state.resultToExamine.enroll.dniimg2 === "" ? (
                                  <div style={{ height: "260px", display: "flex", flexDirection: "column", textAlign: "center", justifyContent: "center", backgroundColor: "#f7f7f7", fontSize: 16, fontWeight: 400, color: "#8c8a88" }}>No tiene</div>
                                ) : (
                                  <Image
                                    style={{ maxHeight: "260px", display: "flex" }}
                                    src={this.state.resultToExamine.enroll.dniimg2}
                                    rounded
                                    fluid
                                    onClick={() => {
                                      this.setState({ photoSelected: 2, photoDialog: true });
                                    }}
                                  />
                                )}
                                {this.state.frenteDni ? (null) : (null)}
                              </CardBody>
                            </Card>
                          </GridItem>
                          {/* Datos */}
                          <GridItem xs={12} sm={12} md={6} lg={6} xl={6} style={{ marginBottom: 0, marginTop: -20 }}>
                            <Card style={{ marginTop: 0 }}>
                              <CardHeader>
                                <h4 style={{ color: "dimgray" }}>Datos de Usuario</h4>
                              </CardHeader>
                              <CardBody>
                                {global.readOnlyUser ? (
                                  <div
                                    style={{
                                      height: 250,
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      textAlign: "center",
                                      flexDirection: "column",
                                    }}
                                  >
                                    <Problem style={{ fontSize: 50, marginBottom: 10 }} />
                                    <div style={{ fontSize: 19 }}>{languages[lang].button_youAreNotAuthorizedToRegister}</div>
                                  </div>
                                ) : (
                                  <div>
                                    {/* Id Usuario */}
                                    <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
                                      <GridContainer style={{ display: "flex" }} alignItems="center" justify="center">
                                        <GridItem xs={4} sm={4} md={4} lg={4} xl={4}>
                                          <h6>ID USUARIO:</h6>
                                        </GridItem>
                                        <GridItem xs={8} sm={8} md={8} lg={8}>
                                          {this.state.resultToExamine.enroll.user}
                                        </GridItem>
                                        <GridItem xs={4} sm={4} md={4} lg={4} xl={4}>
                                          <h6>PRIMER SOLICITUD:</h6>
                                        </GridItem>
                                        <GridItem xs={8} sm={8} md={8} lg={8}>
                                          {this.state.resultToExamine.enroll._firstTimeUser}
                                        </GridItem>
                                        <GridItem xs={4} sm={4} md={4} lg={4} xl={4}>
                                          <h6>ÚLTIMA SOLICITUD:</h6>
                                        </GridItem>
                                        <GridItem xs={8} sm={8} md={8} lg={8}>
                                          {this.state.resultToExamine.enroll._lastChangeUser}
                                        </GridItem>
                                        <GridItem xs={4} sm={4} md={4} lg={4} xl={4}>
                                          <h6>ÚLTIMA AUDITORÍA:</h6>
                                        </GridItem>
                                        <GridItem xs={8} sm={8} md={8} lg={8}>
                                          {this.state.resultToExamine.enroll._lastChangeUni}
                                        </GridItem>
                                        
                                      </GridContainer>
                                    </GridItem>

                                    {/* Dni */}
                                    {/* <GridItem xs={12} sm={12} md={12} lg={12} xl={12} style={{ marginTop: 0, marginBottom: 0, display: "flex", aligntItem: "center", justifyContent: "flex-start" }}>
                                        <GridContainer style={{ marginTop: 0, marginBottom: 0, display: "flex" }} alignItems="center" justify="center">
                                          <GridItem xs={4} sm={4} md={4} lg={4} xl={4}>
                                            <h6>DNI*:</h6>
                                          </GridItem>
                                          <GridItem xs={8} sm={8} md={8} lg={8}> */}
                                    {/* css modify in: src/assets/css/material-dashboard-pro-react.css */}
                                    {/* <TextField
                                              error={this.state._completeElCampo.dni}
                                              className="textfielddni"
                                              style={{ width: "100%" }}
                                              id="textDni"
                                              //disabled={(this.state.resultToExamine.enroll.res !== 'En espera' || global.readOnlyUser === true) ? true : false}
                                              disabled={global.readOnlyUser === true ? true : false || !(global.rol.authorities.permission && global.rol.authorities.permission.editEnrolls == 1)}
                                              type="text"
                                              label={languages[lang].button_id}
                                              variant="outlined"
                                              size="small"
                                              margin="dense"
                                              color="secondary"
                                              value={this.state.textDni}
                                              onChange={(x) => {
                                                let value = x.target.value != null ? x.target.value : "";
                                                this.setState({ textDni: value }, this.checkData);
                                              }}
                                            />
                                          </GridItem>
                                        </GridContainer>
                                      </GridItem> */}

                                    {/* Nombre */}
                                    <GridItem xs={12} sm={12} md={12} lg={12} xl={12} style={{ display: "flex", aligntItem: "center", justifyContent: "flex-start" }}>
                                      <GridContainer style={{ display: "flex" }} alignItems="center" justify="center">
                                        <GridItem xs={4} sm={4} md={4} lg={4} xl={4}>
                                          <h6>NOMBRE*:</h6>
                                        </GridItem>
                                        <GridItem xs={8} sm={8} md={8} lg={8}>
                                          <TextField
                                            error={this.state._completeElCampo.name}
                                            //disabled={(this.state.resultToExamine.enroll.res !== 'En espera' || global.readOnlyUser === true) ? true : false}
                                            disabled={global.readOnlyUser === true ? true : false || !(global.rol.authorities.permission && global.rol.authorities.permission.editEnrolls == 1)}
                                            style={{ width: "100%" }}
                                            id="textName"
                                            label={languages[lang].button_name1}
                                            variant="outlined"
                                            size="small"
                                            margin="dense"
                                            color="secondary"
                                            value={this.state.textName}
                                            onChange={(x) => {
                                              let value = x.target.value != null ? x.target.value : "";
                                              this.setState({ textName: value }, this.checkData);
                                            }}
                                          />
                                        </GridItem>
                                      </GridContainer>
                                    </GridItem>

                                    {/* Apellido */}
                                    <GridItem xs={12} sm={12} md={12} lg={12} xl={12} style={{ display: "flex", aligntItem: "center", justifyContent: "flex-start" }}>
                                      <GridContainer style={{ display: "flex" }} alignItems="center" justify="center">
                                        <GridItem xs={4} sm={4} md={4} lg={4} xl={4}>
                                          <h6>APELLIDO*:</h6>
                                        </GridItem>
                                        <GridItem xs={8} sm={8} md={8} lg={8}>
                                          <TextField
                                            error={this.state._completeElCampo.lastname}
                                            //disabled={(this.state.resultToExamine.enroll.res !== 'En espera' || global.readOnlyUser === true) ? true : false}
                                            disabled={global.readOnlyUser === true ? true : false || !(global.rol.authorities.permission && global.rol.authorities.permission.editEnrolls == 1)}
                                            style={{ width: "100%" }}
                                            id="textLastName"
                                            label={languages[lang].button_lastName1}
                                            variant="outlined"
                                            size="small"
                                            margin="dense"
                                            color="secondary"
                                            value={this.state.textLastName}
                                            onChange={(x) => {
                                              let value = x.target.value != null ? x.target.value : "";
                                              this.setState({ textLastName: value }, this.checkData);
                                            }}
                                          />
                                        </GridItem>
                                      </GridContainer>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
                                      <GridContainer style={{ display: "flex" }} alignItems="center" justify="center">
                                       
                                        <GridItem xs={4} sm={4} md={4} lg={4} xl={4}>
                                          <h6>{global.dni_name}:</h6>
                                        </GridItem>
                                        <GridItem xs={8} sm={8} md={8} lg={8}>
                                          {this.state.resultToExamine.enroll.dni}
                                        </GridItem>
                                        
                                      </GridContainer>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={12} lg={12} xl={12} style={{ display: "flex", aligntItem: "center", justifyContent: "flex-start" }}>
                                      <GridContainer style={{ display: "flex" }} alignItems="center" justify="center">
                                        <GridItem xs={4} sm={4} md={4} lg={4} xl={4}>
                                          <h6>VERIFICAR USUARIO*:</h6>
                                        </GridItem>
                                        <GridItem xs={8} sm={8} md={8} lg={8}>
                                          <FormControl component="fieldset">
                                            <RadioGroup row aria-label="position" name="position" defaultValue="top">
                                              <FormControlLabel
                                                checked={+this.state._userVerify === 1}
                                                value="SI"
                                                control={<Radio color="green" />}
                                                label={"SI"}
                                                labelPlacement="SI"
                                                id="si"
                                                onClick={() => {
                                                  this.verUs(1);
                                                }}
                                              />

                                              <FormControlLabel
                                                checked={+this.state._userVerify === 2}
                                                value="NO"
                                                control={<Radio color="red" />}
                                                label={"NO"}
                                                labelPlacement="NO"
                                                id="no"
                                                onClick={() => {
                                                  this.verUs(2);
                                                }}
                                              />
                                            </RadioGroup>
                                          </FormControl>
                                        </GridItem>

                                        {+this.state._userVerify === 2 ? (
                                          <GridItem xs={12}>
                                            <CustomInput
                                              labelText={languages[lang].button_detailOfRejection}
                                              label={languages[lang].button_detailOfRejection}
                                              id="dismissDetail"

                                              success
                                              autoFocus={true}
                                              formControlProps={{
                                                fullWidth: true,
                                              }}
                                              inputProps={{
                                                defaultValue: this.textDescription,
                                                placeholder: "Detalle*",
                                                //value:this.state.textDescription,
                                                // onKeyPress: (e) => this._handleKeyPress(e),
                                                onKeyDown: (e) => this.handleKeyDown(e),
                                                onChange: (e) => this.handleOnChange(e),
                                              }}
                                            />
                                          </GridItem>
                                        ) : null}
                                      </GridContainer>
                                    </GridItem>
                                  </div>
                                )}
                                {global.readOnlyUser ? (
                                  <div />
                                ) : (
                                  <GridItem xs={12} sm={12} md={12} lg={12} xl={12} style={{ width: "100%", display: "flex", alignItem: "flex-end", justifyContent: "flex-end" }}>
                                    <Button
                                      style={{ marginRight: "10px" }}
                                      // style={{ marginRight: "10px", color: '#FFFFFF', backgroundColor: "#f44336" }}
                                      color="danger"
                                      size="sm"
                                      onClick={() => {
                                        let row = this.state._resultToExamine.enroll
                                        this.setState({
                                          _dismissDialog: true,
                                          _buttonDismiss: false,
                                          showVe: !this.state.showVe,
                                          textDni: row.dni,
                                          textName: (row.nombre === '-') ? (row.res === 'En espera') ? '' : '-' : row.nombre,
                                          textLastName: (row.apellido === '-') ? (row.res === 'En espera') ? '' : '-' : row.apellido,
                                          //_buttonAccept: false,
                                        });
                                      }}
                                    >
                                      Cancelar
                                    </Button>
                                    <Button
                                      // style={{ color: '#FFFFFF', backgroundColor: "#4caf50" }}
                                      color="success"
                                      size="sm"
                                      disabled={!this.state._buttonAccept || !(global.rol.authorities.permission && global.rol.authorities.permission.editEnrolls == 1) || this.state._userVerify === null}
                                      onClick={() => {
                                        if (this.state._buttonAccept && (global.rol.authorities.permission && global.rol.authorities.permission.editEnrolls == 1)) {
                                          this.setState({ loading: true }, this.acceptEnroll);
                                        }
                                      }}
                                    >
                                      Guardar
                                    </Button>
                                  </GridItem>
                                )}
                              </CardBody>
                            </Card>
                          </GridItem>
                          {/* Botones o Descripción
{(this.state.resultToExamine.enroll.res !== 'En espera') ?

<GridItem xs={12} sm={12} md={12} lg={12} xl={12} style={{ marginTop: 0, width: "100%" }} >
<Card>
<CardBody>
<GridContainer>
<GridItem xs={9} sm={9} md={4} lg={4} xl={4} style={{ display: "flex", alignItem: "center", justifyContent: "flex-start" }}>
<div style={{ color: "black" }}>Estado de solicitud de empadronamiento:</div>
</GridItem>
<GridItem xs={3} sm={3} md={2} lg={2} xl={2} style={{ display: "flex", alignItem: "center", justifyContent: "flex-start" }}>
<div style={{ color: "grey" }}>{(this.state.resultToExamine.enroll.res === 'Aprobada') ? 'Solicitud aceptada' : 'Solicitud rechazada'}</div>
</GridItem>

<GridItem xs={2} sm={2} md={1} lg={1} xl={1} style={{ display: "flex", alignItem: "center", justifyContent: "flex-start" }}>
<div style={{ color: "black" }}>Detalle:</div>
</GridItem>
<GridItem xs={10} sm={10} md={5} lg={5} xl={5} style={{ display: "flex", alignItem: "center", justifyContent: "flex-start" }}>
<div style={{ color: "grey" }}>{(this.state.resultToExamine.enroll.res === 'Aprobada') ? '-' : (this.state.resultToExamine.enroll.det === '') ? 'No contiene Detalle' : this.state.resultToExamine.enroll.det}</div>
</GridItem>
</GridContainer>
</CardBody>
</Card>
</GridItem> */}
                          :
                          <div />
                        </GridContainer>

                        {/* <GridItem xs={12} sm={12} md={12} lg={4} xl={4} style={{}} >
<h4 style={{ color: 'dimgray' }}>{this.state.resultToExamine.userName ? this.state.resultToExamine.userName : "-"}</h4>
</GridItem>

<GridItem xs={12} sm={6} md={6} lg={4} xl={4} style={{}} >
<h4 className={classes.cardIconTitle} style={{ color: 'dimgray' }}>{this.state.resultToExamine.file ? this.state.resultToExamine.file : "-"}</h4>
</GridItem>

<GridItem xs={9} sm={3} md={3} lg={2} xl={2} style={//{ width: "100%", display: "flex", justifyContent: "center", alignItems: 'center' }
{}} >
<h4 className={classes.cardIconTitle} style={{ color: 'dimgray' }}>{this.state.resultToExamine.validation ? <ValidationLabel validation={this.state.resultToExamine.validation} /> : "-"}</h4>
</GridItem> */}




                      </GridContainer>
                      {/* } */}
                    </Collapse>
                  </GridItem>
                </GridContainer>
                : null}
            </Card>
            {/* :null} */}

            <Card style={{ marginTop: 0 }}>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12} lg={12} xl={12} style={{ margin: 10 }}>
                  {this.state.resultToExamine && this.state._resultToExamine && this.state._resultToExamine.previous_attempts != null ? (
                    <GridContainer style={{ marginTop: 0, paddingLeft: 10 }}>
                      <GridItem xs={9} sm={9} md={9} lg={10} xl={10} style={{}}>
                        <h4 style={{ color: "dimgray" }}>{languages[lang].examsNA_generalInformation}</h4>
                      </GridItem>

                      <GridItem xs={3} sm={3} md={3} lg={2} xl={2} style={{ width: "100%", position: "float", display: "flex", justifyContent: "flex-end", alignItems: "flex-end" }}>
                        <IconButton
                          style={{ outline: "none" }}
                          aria-expanded={this.state.showProfileInfo}
                          aria-label="show more"
                          onClick={() => {
                            this.setState({
                              showProfileInfo: !this.state.showProfileInfo,
                            });
                          }}
                        >
                          {this.state.showProfileInfo ? <ExpandLess /> : <ExpandMoreIcon />}
                        </IconButton>
                      </GridItem>
                    </GridContainer>
                  ) : (
                    <GridContainer style={{ marginTop: 0, paddingLeft: 10 }}>
                      <GridItem xs={12} sm={12} md={12} lg={4} xl={4} style={{}}>
                        <h4 style={{ color: "dimgray" }}>{this.state.resultToExamine.userName ? this.state.resultToExamine.userName : "-"}</h4>
                      </GridItem>

                      <GridItem xs={12} sm={6} md={6} lg={4} xl={4} style={{}}>
                        <h4 className={classes.cardIconTitle} style={{ color: "dimgray" }}>
                          {this.state.resultToExamine.file ? this.state.resultToExamine.file : "-"}
                        </h4>
                      </GridItem>

                      <GridItem
                        xs={9}
                        sm={3}
                        md={3}
                        lg={2}
                        xl={2}
                        style={
                          //{ width: "100%", display: "flex", justifyContent: "center", alignItems: 'center' }
                          {}
                        }
                      >
                        <h4 className={classes.cardIconTitle} style={{ color: "dimgray" }}>
                          {this.state.resultToExamine.validation ? <ValidationLabel validation={this.state.resultToExamine.validation} /> : "-"}
                        </h4>
                      </GridItem>

                      <GridItem xs={3} sm={3} md={3} lg={2} xl={2} style={{ width: "100%", position: "float", display: "flex", justifyContent: "flex-end", alignItems: "flex-end" }}>
                        <IconButton
                          // disabled={!this.state.resultToExamine.previous_attempts}
                          style={{ outline: "none" }}
                          aria-expanded={this.state.showProfileInfo}
                          aria-label="show more"
                          onClick={() => {
                            this.setState({
                              showProfileInfo: !this.state.showProfileInfo,
                            });
                          }}
                        >
                          {this.state.showProfileInfo ? <ExpandLess /> : <ExpandMoreIcon />}
                        </IconButton>
                      </GridItem>
                    </GridContainer>
                  )}

                  <Collapse in={this.state.showProfileInfo} timeout="auto" unmountOnExit>
                    <GridItem xs={12} sm={12} md={12} lg={12} xl={12} style={{ marginTop: 5, marginBottom: 5 }}>
                      <div style={{ width: "99.4%", height: 5, borderTop: "1px solid #ced4db" }} />
                    </GridItem>

                    <KWValidationFormProfile
                      examType={"Complete"}
                      data={this.state.resultToExamine}
                      _data={this.state._resultToExamine}
                      incidenceCount={this.state.incidenceCount}
                      exam_table_fields={global.exam_table_fields}
                    //showInfoPc={this.state.showInfoPc}
                    //showInfoPcChange={() => {
                    //this.setState({
                    //showInfoPc: !this.state.showInfoPc
                    //})
                    //}}
                    />
                  </Collapse>
                </GridItem>
              </GridContainer>
            </Card>

            <Card style={{ marginTop: 20 }}>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12} lg={12} xl={12} style={{ margin: 10 }}>
                  <GridContainer>
                    <GridItem xs={8} sm={8} md={8} lg={9} xl={9} style={{}}>
                      <h4 style={{ color: "dimgray", marginLeft: 10 }}>{languages[lang].examsNA_visualAudit}</h4>
                    </GridItem>

                    <GridItem xs={4} sm={4} md={4} lg={3} xl={3} style={{ width: "100%", position: "float", display: "flex", justifyContent: "flex-end", alignItems: "flex-end" }}>
                      <IconButton
                        style={{ outline: "none" }}
                        aria-expanded={this.state.showVideoAudit}
                        // aria-expanded={this.state.showVideoAudit && this.state.resultToExamine.enroll.ve && this.state.resultToExamine.enroll.ve === 1 ? this.state.showVideoAudit : this.state.showVideoAudit=false }
                        aria-label="show more"
                        onClick={() => {
                          this.setState({
                            showVideoAudit: !this.state.showVideoAudit,
                          });
                        }}
                      >
                        {this.state.showVideoAudit ? <ExpandLess /> : <ExpandMoreIcon />}
                      </IconButton>
                    </GridItem>
                  </GridContainer>
                </GridItem>
              </GridContainer>
              <Collapse in={this.state.showVideoAudit} timeout="auto" unmountOnExit>
                <GridItem xs={12} sm={12} md={12} lg={12} xl={12} style={{ marginTop: 5, marginBottom: 5, display: "flex", justifyContent: "center" }}>
                  <div style={{ width: "99.3%", height: 5, borderTop: "1px solid #ced4db" }} />
                </GridItem>
                <div id="tableAnomalies" ref={this.myRef} />
                {this.state.showValidateForm ? (
                  <KWValidationFormVideo
                    data={this.state.resultToExamine}
                    _data={this.state._resultToExamine}
                    changeIncidence={(value) => {
                      this.setState({
                        incidenceFilter: value,
                      });
                    }}
                    incidenceSelected={this.state.incidenceFilter}
                  />
                ) : null}
              </Collapse>
            </Card>

            <Card style={{ marginTop: 0 }}>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12} lg={12} xl={12} style={{ margin: 10 }}>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12} lg={12} xl={12} style={{}}>
                      <h4 style={{ color: "dimgray", marginLeft: 10 }}>{languages[lang].examsNA_percentageOAnomalies}</h4>
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12} lg={12} xl={12} style={{}}>
                      <KWAnomaliesSummary
                        changeAnomaly={(x) => {
                          let newData = JSON.parse(JSON.stringify(this.state._2resultToExamine));
                          let newRes = newData.incidences.filter((item) => {
                            if (item._key === x) {
                              return true;
                            }
                            return false;
                          });

                          newData.incidences = newRes;
                          this.setState({ resultToExamine: newData, filteredAnomaly: true });
                          //this.myRef.current.scrollTop = 0;
                          //this.scrollTable()
                          this.focusMethod();
                          //    this.setState({resultToExamine:newData})
                        }}
                        data={this.state.resultToExamine}
                        // _data={this.state._resultToExamine}
                        filteredAnomaly={this.state.filteredAnomaly}
                        clearFilter={() => {
                          let newData = JSON.parse(JSON.stringify(this.state._2resultToExamine));
                          this.setState({ resultToExamine: newData, filteredAnomaly: false });
                          this.focusMethod();
                        }}

                      />
                    </GridItem>
                  </GridContainer>
                </GridItem>
              </GridContainer>
            </Card>

            <Card style={{ marginTop: 0 }}>

              <GridContainer>
                <GridItem xs={12} sm={12} md={12} lg={12} xl={12} style={{ margin: 10 }} >
                  {this.state.showValidateForm ?
                    <KWValidationFormAuditResult
                      classes={classes}
                      examType={"Complete"}
                      showLoading={(value) => {
                        this.setState({
                          loading: value
                        })
                      }}
                      data={this.state.resultToExamine}

                      _data={this.state._resultToExamine}

                      auditResult={this.state.auditResult}
                      auditResultChange={(value) => {
                        this.setState({
                          auditResult: value
                        })
                      }}
                      auditText={this.state.auditText}
                      auditTextChange={(value) => {
                        this.setState({
                          auditText: value
                        })
                      }}
                      saveAuditResult={() => {
                        this.updateValidationTable()
                      }}
                    />
                    : null}
                </GridItem>
              </GridContainer>
            </Card>
          </DialogContent>

          {/*
            <DialogActions > <CustomButton color="danger" simple onClick={() => { this.setState({ showValidateForm: false }) } }>Cancelar</CustomButton> </DialogActions>
          */}
        </Dialog>
      </div>
    );
  }
}

export default withStyles(sweetAlertStyle)(ExamnsNewAudit);

import React from 'react'

import Problem from '@material-ui/icons/ReportProblem'
//import cellEditFactory, { Type } from 'react-bootstrap-table2-editor'

import GridContainer from "components/Grid/GridContainer.jsx"
import GridItem from "components/Grid/GridItem.jsx"
import CardBody from "components/Card/CardBody.jsx"

import BootstrapTable from 'react-bootstrap-table-next'

import BivPagination from 'components/BivPagination/BivPagination.jsx'

import moment from 'moment'
import { Hidden } from '@material-ui/core'
moment.locale("es")

// function sizeIdColumn (data) {
  
//   if(data.dni){
//     maxWidth:100
//   }
// }

function BivProctoringTable(props) {

  const {
    data,
    getColumnData,
    pageNumber,
    pageNumberChange,
    totalRegistry,
    textNoData,
    zoom,
  } = props

  BivProctoringTable.defaultProps = {
    data: [],
    getColumnData: [],
    pageNumber: 1,
    pageNumberChange: () => null,
    totalRegistry: 0,
    textNoData: '',
    zoom: 1,
  }

  return (

    <div>

      {data.length > 0 ?
 
        <div>
          {/* Datos de Tabla , overflow: "scroll" */}
          <CardBody style={{ maxHeight: 2275, overflow: "auto" }}>
            <GridContainer justify="space-between">
              <GridItem xs={12} sm={12} md={12} style={{ minWidth: 1050, zoom: (zoom === 1) ? '100%' : (zoom === 2) ? '90%' : (zoom === 3) ? '80%' : (zoom === 4) ? '70%' : '60%' }}>
                <BootstrapTable 
                  // style={{maxWidth:100}}
                  bootstrap4
                  keyField="key"
                  data={data}
                  columns={getColumnData}
                  striped
                  bordered={true}
                  wrapperClasses="table-responsive"
                  classes='scrollable-table'
                />
              </GridItem>
            </GridContainer>
          </CardBody>

          <BivPagination
            onClickPrev={() => {
              if (pageNumber !== 1) {
                pageNumberChange(pageNumber - 1)
              }
            }}
            onClickPage={(value) => {
              pageNumberChange(value)
            }}
            onClickNext={() => {
              if (pageNumber !== totalRegistry) {
                pageNumberChange(pageNumber + 1)
              }
            }}
            page={pageNumber}
            totalRegistry={totalRegistry}
          />

        </div>

        :

        <GridContainer>
          <GridItem xs={12}
            style={{
              height: 250,
              display: "flex",
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
              flexDirection: 'column'
            }}>
            <Problem style={{ fontSize: 50, marginBottom: 10 }}></Problem>
            {/*<div style={{ fontSize: 19 }}>No hay examenes iniciales, utilice los filtros para obtener resultados.</div>*/}
            <div style={{ fontSize: 19 }}>{textNoData}</div>
          </GridItem>
        </GridContainer>

      }

    </div >
  )

}

export default BivProctoringTable

